import { useLocation } from "react-router";

export const useQueryParams = (queryParam: string) => {
  const searchParams = new URLSearchParams(useLocation().search);
  return searchParams.getAll(queryParam);
};

export const useQueryParam = (queryParam: string) => {
  const searchParams = new URLSearchParams(useLocation().search);
  return searchParams.get(queryParam);
};
