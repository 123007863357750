import { LatLng } from "leaflet";
import { MatrixGraphData, MyMapClick, ScoreResult } from "../types";
import { RasterQuantiles } from "guppy.ts";
import { round } from "lodash";

function areLatLngsEqual(a: LatLng, b: LatLng): boolean {
  return a.lat === b.lat && a.lng === b.lng;
}
export function useMergedFsWsGraphData(
  foodswampResults: ScoreResult[],
  walkscoreResults: ScoreResult[],
  markers: MyMapClick[]
) {
  console.log("foodswampResults: ", foodswampResults, walkscoreResults);
  const mergedResults = foodswampResults.reduce((acc: MatrixGraphData[], fsResult: ScoreResult) => {
    const wsResult = walkscoreResults.find((wsResult) => areLatLngsEqual(wsResult.latLng, fsResult.latLng));
    const marker = markers.find((marker) => areLatLngsEqual(marker.latLng, fsResult.latLng));
    if (!wsResult || !marker) return [...acc];
    const newEntry = {
      walkscore: wsResult.mappedValue,
      foodswamp: fsResult.mappedValue,
      latLng: fsResult.latLng,
      color: marker.markerColor || "red",
      walkscoreRawValue: wsResult.value,
      foodswampRawValue: fsResult.value,
    };
    return [...acc, newEntry];
  }, []);
  return mergedResults;
}

export function useGraphTicks(quantiles: { xAxisQuantiles: RasterQuantiles[]; yAxisQuantiles: RasterQuantiles[] }) {
  const { tickMapping: xTickMapping, ticks: xTicks } = generateTickMapping(quantiles.xAxisQuantiles);
  const { tickMapping: yTickMapping, ticks: yTicks } = generateTickMapping(quantiles.yAxisQuantiles);

  return { yTickMapping, yTicks, xTickMapping, xTicks };
}

function generateTickMapping(quantiles: RasterQuantiles[]) {
  const interval = 100 / (quantiles.length - 1);

  const result = quantiles.reduce<{ tickMapping: { [key: number]: string }; ticks: number[] }>(
    (acc, quantile, index) => {
      const tick = Math.round(interval * index);
      acc.tickMapping[tick] = round(quantile.value, 2).toString();
      acc.ticks[index] = tick;
      return acc;
    },
    { tickMapping: {}, ticks: [] }
  );

  return result;
}
