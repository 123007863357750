import React, { MutableRefObject } from "react";
import styled from "styled-components";
import { Drawer, List, Portal, Toolbar } from "@material-ui/core";
import { PaperBackground } from "./surface/PaperBackground";
import {
  ListItemAddressSearch,
  ListItemAnalyse,
  ListItemLayers,
  ListItemSettings,
  ListItemZoneDistance,
} from "./actions";
import { ListItemFoodSwamps } from "./actions/ListItemFoodSwamps";

interface Props {
  actionBarContainer: MutableRefObject<HTMLDivElement | null>;
}

export function Actionbar(props: Props) {
  const { actionBarContainer } = props;

  return (
    <Portal container={actionBarContainer.current}>
      <StyledDrawer
        variant="persistent"
        open={true}
        elevation={12}
        anchor="right"
        PaperProps={{ component: PaperBackground }}
      >
        <Toolbar />
        <List className="list">
          <ListItemLayers />
          <ListItemAddressSearch
            label={"Zoek op straat/adres"}
            name="street"
            className="adres-field"
            variant="standard"
            error={false}
            helperText={""}
          />
          <ListItemAnalyse />
          <ListItemZoneDistance />
          <ListItemFoodSwamps />
          <ListItemSettings />
        </List>
      </StyledDrawer>
    </Portal>
  );
}

const StyledDrawer = styled(Drawer)`
  width: 60px;
  overflow: visible;

  .list {
    padding: 0;
  }

  .list-item {
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
