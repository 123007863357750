import { RasterQuantiles } from "guppy.ts";

export const baseQuantiles: RasterQuantiles[] = [
  { quantile: 0, value: -3.65 },
  { quantile: 1, value: -1.93 },
  { quantile: 2, value: -1.16 },
  { quantile: 3, value: -0.08 },
  { quantile: 4, value: 28.85 },
];

export const foodswampQuantiles: RasterQuantiles[] = [
  {
    quantile: 0,
    value: 0,
  },
  {
    quantile: 1,
    value: 1.4,
  },
  {
    quantile: 2,
    value: 3.8,
  },
  {
    quantile: 3,
    value: 6.7,
  },
  {
    quantile: 4,
    value: 10,
  },
];
