import { Paper } from "@material-ui/core";
import styled from "styled-components";

export const PaperBackground = styled(Paper)`
  width: 60px;
  background: #e5eef3;

  &.large {
    width: 330px;
    padding: ${({ theme }) => `${theme.spacing(3, 3)}`};
  }
`;

export const MenuPaper = styled(Paper)`
  padding: ${({ theme }) => `${theme.spacing(2, 2)}`};
`;

export const PanePaper = styled(Paper)`
  right: ${({ theme }) => `${theme.spacing(7.5)}px`};
  width: 330px;
  padding: ${({ theme }) => `${theme.spacing(3, 2, 2, 2)}`};
  overflow-x: hidden;
`;

export const MapWindowPaper = styled(Paper)`
  padding: ${({ theme }) => `${theme.spacing(2, 2)}`};
  border-radius: 0;
`;
