import { Typography } from "@material-ui/core";
import { template } from "lodash-es";
import { useAtom } from "jotai";
import { LatLng, LatLngBounds } from "leaflet";

import { usePlaceName, useBelfiusClusterInfo, useRasterData, useRasterQuantiles } from "../../hooks";
import { BELGIUM_GEOMETRY, QUANTILE_BINS } from "../../config";
import { MyMapClick, SpatialOverlayer } from "../../types";
import { boundsAtom } from "../../atoms/bounds.atom";
import { activeBelfiusOverlayerAtom } from "../../atoms/overlayers.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { latLngBoundsToWkt } from "../../utils/geometry.utils";
import { getQuantileLabel } from "../../utils/quantile.utils";
import { baseQuantiles } from "../../config/quantiles";
import { useFeatureInfo, useStatisticalSectorShape } from "../../hooks/analyseHooks";

interface Props {
  overlayer: SpatialOverlayer;
  mapClick: MyMapClick;
}

export const MapAnalysisContent = (props: Props) => {
  const { mapClick, overlayer } = props;

  const [rescaledBounds] = useAtom(boundsAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);
  const [isLegendRescaled] = useAtom(isLegendRescaledAtom);
  const { data: placeName } = usePlaceName(mapClick);
  const { data: score } = useRasterData(overlayer.wmsLayer.layers, mapClick.latLng, {
    enabled: overlayer.isShapeFile === false,
  });
  const { data: statSecFeature } = useStatisticalSectorShape(mapClick.latLng);

  const { data: feature } = useFeatureInfo(overlayer.wmsLayer.layers, overlayer.wmsLayer.url, mapClick.latLng, {
    enabled: overlayer.isShapeFile,
  });

  const value = feature?.properties && overlayer.attributeKey ? feature?.properties[overlayer.attributeKey] : score;

  const { data: belfiusClusterInfo } = useBelfiusClusterInfo(mapClick, value!, overlayer, { enabled: !!score });

  const { data: quantilesBelgium } = useRasterQuantiles(overlayer, QUANTILE_BINS, BELGIUM_GEOMETRY, {
    enabled: overlayer.isShapeFile === false ? true : false,
  });

  const quantiles = quantilesBelgium ? quantilesBelgium : baseQuantiles;

  if (statSecFeature && statSecFeature.properties) {
    console.log(statSecFeature.properties["NISCODE"]);
    console.log(statSecFeature.properties["SECNAAM"]);
  }

  return render();

  function render() {
    if (!overlayer) return null;
    if (!value) return null;

    return (
      <>
        <Typography variant="h4">{placeName}</Typography>
        {belfiusClusterInfo && (
          <Typography variant="body1">
            <strong>Belfius-cluster:</strong> {belfiusClusterInfo.clusterName}
          </Typography>
        )}
        <br />
        {statSecFeature && statSecFeature.properties && (
          <>
            <Typography variant="body1">
              <strong>Statistische sector:</strong> {statSecFeature.properties["SECNAAM"] || ""}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>NISCODE:</strong> {statSecFeature.properties["NISCODE"] || ""}
            </Typography>
          </>
        )}

        {quantiles && (
          <Typography variant="body1">
            {getQuantileLabel(quantiles, value)} ten opzichte van <strong>Vlaanderen en Brussel (BHG)</strong>
          </Typography>
        )}

        {belfiusClusterInfo && (
          <Typography variant="body1">
            {belfiusClusterInfo.rangeLabel} ten opzichte van <strong>Belfius-cluster</strong>
          </Typography>
        )}
        {renderRescaledAnalysis()}
        <br />
        <Typography variant="body1">
          <strong>{overlayer.name}:</strong> {value}
        </Typography>

        {overlayer.extraDataLayers?.map((extraDataLayer, i) => (
          <ExtraDataLayerInfo key={i} latlng={props.mapClick.latLng} extraDataLayer={extraDataLayer} />
        ))}
      </>
    );
  }

  function renderRescaledAnalysis() {
    if (!overlayer) return null;
    if (!isLegendRescaled) return null;
    if (!rescaledBounds) return null;
    if (!score) return null;

    return (
      <RescaledAnalysis
        score={score}
        overlayer={overlayer}
        useRescaledLegend={isLegendRescaled}
        rescaledBounds={rescaledBounds}
        belfiusOverlayerKey={activeBelfiusOverlayer?.key || ""}
      />
    );
  }
};

function ExtraDataLayerInfo(props: { latlng: LatLng; extraDataLayer: { layer: string; label: string } }) {
  const { extraDataLayer, latlng } = props;
  const { data } = useRasterData(extraDataLayer.layer, latlng);
  const compiledTemplate = template(extraDataLayer.label);
  // const compiledTemplate = template('hello <%= value %>!');

  return render();

  function render() {
    if (data === undefined) return null;

    return (
      <Typography variant="body1">
        {/* <strong>{extraDataLayer.label}:</strong> {data} */}
        <strong>{compiledTemplate({ value: data })}:</strong>
      </Typography>
    );
  }
}

function RescaledAnalysis(props: {
  score: number;
  overlayer: SpatialOverlayer;
  useRescaledLegend: boolean;
  rescaledBounds: LatLngBounds;
  belfiusOverlayerKey: SpatialOverlayer["key"];
}) {
  const { data: quantilesForBoundArea } = useRasterQuantiles(
    props.overlayer,
    QUANTILE_BINS,
    latLngBoundsToWkt(props.rescaledBounds) as string
  );

  return render();

  function render() {
    if (!quantilesForBoundArea) return null;
    return (
      <Typography variant="body1">
        {getQuantileLabel(quantilesForBoundArea, props.score)} ten opzichte van <strong>herschaald gebied</strong>
      </Typography>
    );
  }
}
