import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface Props extends SvgIconProps {}

export function Rhombus(props: Props) {
  const { ...svgProps } = props;
  return (
    <SvgIcon {...svgProps}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg">
        <g id="layer1">
          <path id="path18" d="M 12.000001,0.275242 2.0519962,11.999999 12.000001,23.724758 21.948004,11.999999 Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}
