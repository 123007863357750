import { BaseOverlayer } from "../../types";

export const foodswamOverlayers: BaseOverlayer[] = [
  {
    key: "moerasscore",
    name: "Moerasscore",
    isActive: false,
    attributeKey: "Moerasscor",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:20230712_statsec_walkabilityscore_voedselmoerasscore_kwetsbare_buurten",
      styles: "walkability-v2:moerasscore",
    },
  },
  {
    key: "Voedselwoestijnen",
    name: "Voedselwoestijnen",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:potential_fooddeserts_allresidential_areas",
    },
  },
];
