import { RasterQuantiles } from "guppy.ts";
import { BaseOverlayer, SpatialOverlayer } from "../../types";
import { useMarkers, useMultipleRasterData } from "../../hooks";
import { useMultipleMarkerFeatureScores } from "../../hooks/analyseHooks";
import { foodswampQuantiles } from "../../config/quantiles";
import { useMergedFsWsGraphData } from "../../hooks/foodswampMatrixHooks";
import { MatrixGraph } from "./MatrixGraph";
import { MatrixMarkerList } from "./MatrixMarkerList";

interface Props {
  layer: SpatialOverlayer;
  quantiles: RasterQuantiles[];
  foodswampLayer: BaseOverlayer;
}

export function MatrixData(props: Props) {
  const { layer, quantiles, foodswampLayer } = props;
  const markers = useMarkers();

  const featureWalkscoreResults = useMultipleMarkerFeatureScores(markers, layer, quantiles, layer.isShapeFile);
  const featureFoodswampResults = useMultipleMarkerFeatureScores(markers, foodswampLayer, foodswampQuantiles, true);
  const rasterResults = useMultipleRasterData(markers, layer, quantiles, !layer.isShapeFile);
  console.log("Raster results: ", rasterResults);

  const graphData = useMergedFsWsGraphData(
    featureFoodswampResults,
    layer.isShapeFile ? featureWalkscoreResults : rasterResults,
    markers
  );
  return (
    <>
      <MatrixGraph foodswampQuantiles={foodswampQuantiles} walkscoreQuantiles={quantiles} data={graphData} />
      <MatrixMarkerList data={graphData} />
    </>
  );
}
