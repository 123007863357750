import { debounce, Drawer, IconButton, Toolbar } from "@material-ui/core";
import { ChevronLeft as ChevronLeftIcon, ChevronRight } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";

import { BelfiusClustersSelection } from "./BelfiusClustersSelection";
import { ImagesFooter } from "./ImagesFooter";
import { WalkabilityIndicatorSelection } from "./WalkabilityIndicatorSelection";
import { InformationSubject } from "./InformationDialog";
import { Spacer } from "./Spacer";
import { DemographicIndicatorSelection } from "./DemographicIndicatorSelection";
import { HistoryState } from "../types";
import { SelectionSidebarActions } from "./SelectionSidebarActions";

interface Props {
  className?: string;
}

export function SelectionSidebar({ className }: Props) {
  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();

  const [isOpen, setIsOpen] = useState(true);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  function handleShowMoreInformation(subject: InformationSubject) {
    history.push(`${location.pathname}${location.search}`, { informationDialogSubject: subject });
  }

  return render();

  function render() {
    if (isOpen) return renderOpen();
    else return renderClosed();
  }

  function renderOpen() {
    return (
      <Styles>
        <IconButton onClick={debounce(toggleIsOpen, 230)} className="btn-drawer-toggle" size="small">
          <ChevronLeftIcon fontSize="small" />
        </IconButton>
        <Drawer variant="persistent" open={isOpen} elevation={12} className={isOpen ? "open" : "closed"}>
          <Toolbar />

          <div className="content-container">
            <div className="selection-container">
              <WalkabilityIndicatorSelection
                onClickMoreInformation={() => handleShowMoreInformation("walkabilityscore")}
              />
              <DemographicIndicatorSelection onClickMoreInformation={() => handleShowMoreInformation("demographic")} />
              <BelfiusClustersSelection onClickMoreInformation={() => handleShowMoreInformation("belfius-clusters")} />
              <SelectionSidebarActions />
              <Spacer />
              <ImagesFooter />
            </div>
          </div>
        </Drawer>
      </Styles>
    );
  }

  function renderClosed() {
    return (
      <ReopenStyles>
        <IconButton onClick={debounce(toggleIsOpen, 230)} edge="start" size="small">
          <ChevronRight />
        </IconButton>
      </ReopenStyles>
    );
  }
}

const Styles = styled.div`
  --main-bg-color: #e5eef3;
  --sidebar-width: 330px;
  --toggle-button-size: 30px;

  .MuiDrawer-root {
    &.open {
      width: var(--sidebar-width);
    }

    &.closed {
      min-width: 30px;
      position: relative;
      overflow: hidden;
    }
  }

  .MuiPaper-root {
    width: var(--sidebar-width);
    background-color: var(--main-bg-color);
  }

  .content-container {
    overflow: auto;
  }

  .btn-drawer-toggle {
    position: absolute;
    top: 50%;
    left: calc(var(--sidebar-width) - (var(--toggle-button-size) / 2));
    width: var(--toggle-button-size);
    height: var(--toggle-button-size);
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: var(--main-bg-color);
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  }

  .selection-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => `${theme.spacing(3, 3)}`};
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: ${({ theme }) => theme.spacing(2)}px;
    padding: ${({ theme }) => theme.spacing(0, 4)};

    .icon {
      width: 40px;
      height: 40px;
      background: ${({ theme }) => `${theme.palette.background.paper}`};

      svg {
        width: 15px;
        fill: black;
      }
    }
  }
`;

const ReopenStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  height: 100vh;
  padding: ${({ theme }) => theme.spacing(0.5, 0.5)};

  overflow: hidden;
  background-color: ${({ theme }) => `${theme.palette.common.white}`};
`;
