import styled from "styled-components";
import { Room as RoomIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useAtom } from "jotai";

import { useMarkers } from "../../hooks";
import { useRasterData } from "../../hooks/useRasterData";
import { MyMapClick, SpatialOverlayer } from "../../types";
import { OutOfBoundsErrorBoundary } from "../OutOfBoundsErrorBoundary";
import { activeSpatialOverlayerAtom, spatialOverlayersAtom } from "../../atoms/overlayers.atom";
import { Spacer } from "../Spacer";
import { useUpdateSearchParams } from "../../hooks/routerHooks";
import { useFeatureInfo } from "../../hooks/analyseHooks";
import { round } from "lodash-es";
import { Rhombus } from "../icons/Rhombus";

interface Props {
  marker: MyMapClick;
  index: number;
  displayStatisticalSector: boolean;
}

export const MarkerDetails = (props: Props) => {
  const { marker, index, displayStatisticalSector } = props;
  const update = useUpdateSearchParams();
  const markers = useMarkers();
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [allSpatialOverlayers] = useAtom(spatialOverlayersAtom);
  const statisticalSectorOverlayer = allSpatialOverlayers.find((overlayer) => overlayer.isStatisticalSectorData);

  const activeLayer = displayStatisticalSector ? statisticalSectorOverlayer : activeSpatialOverlayer;

  function deleteMarker(index: number) {
    const newMarkers = [...markers];
    newMarkers.splice(index, 1);
    const newMarkerParams = newMarkers.map((marker) => marker.latLng.lat + "," + marker.latLng.lng);
    update("markers", newMarkerParams);
  }

  return (
    <MarkerRow key={index}>
      {!displayStatisticalSector && (
        <RoomIcon className="marker-icon" style={{ fill: marker.markerColor }} fontSize="medium" />
      )}
      {displayStatisticalSector && <Rhombus fontSize="small" style={{ fill: marker.markerColor }} />}
      <OutOfBoundsErrorBoundary>
        {activeLayer && <MarkerInfo marker={marker} layer={activeLayer} />}
      </OutOfBoundsErrorBoundary>

      <Spacer />
      <IconButton
        size="small"
        onClick={() => {
          deleteMarker(index);
        }}
      >
        <DeleteIcon fontSize="small" style={{ color: "#D1D1D1" }} />
      </IconButton>
    </MarkerRow>
  );
};

interface MarkerInfoProps {
  marker: MyMapClick;
  layer: SpatialOverlayer;
}

const MarkerInfo = (props: MarkerInfoProps) => {
  const { marker, layer } = props;

  const { data: score } = useRasterData(layer.wmsLayer.layers, marker.latLng, {
    enabled: layer.isShapeFile === false,
  });

  const { data: feature } = useFeatureInfo(layer.wmsLayer.layers, layer.wmsLayer.url, marker.latLng, {
    enabled: layer.isShapeFile,
  });

  const value = feature?.properties ? feature?.properties["walkscore"] : score;
  if (!value) {
    return null;
  }

  return (
    <div className="marker-data">
      <div className="label">{layer.name}</div>
      {"-"}
      <div className="score"> {round(value, 2)}</div>
    </div>
  );
};

const MarkerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)}px;

  .marker-icon {
    transform: translateY(-1px);
  }

  .marker-data {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;

    gap: ${({ theme }) => theme.spacing(1)}px;

    .label {
      flex: 1 1 auto; /* Allow the label to grow and shrink */
      font-size: 0.8rem;
      word-break: break-word; /* Break words to prevent overflow */
    }
    .score {
      flex-shrink: 0;
      font-weight: bold;
    }
  }
`;
