import { divIcon } from "leaflet";
import { Marker } from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import { Room as RoomIcon } from "@material-ui/icons";

import { useMarkers } from "../../hooks";
import { useAnalysisType } from "../../hooks/analyseHooks";
import { RenderStatisticalSectorGeojson } from "./RenderStatisticalSectorGeojson";
import { Suspense } from "react";

export const MapMarkers = () => {
  const markers = useMarkers();
  const { analysisType } = useAnalysisType();

  return (
    <>
      {analysisType === "point" &&
        markers.map((value, index) => (
          <Marker
            key={`marker-${index}`}
            position={value.latLng}
            icon={divIcon({
              html: ReactDOMServer.renderToString(
                <RoomIcon className="marker-icon" fontSize="large" style={{ fill: value.markerColor }} />
              ),
            })}
          />
        ))}
      {analysisType === "statSec" &&
        markers.map((value, index) => (
          <Suspense fallback={null} key={`marker-${index}`}>
            <RenderStatisticalSectorGeojson latLng={value.latLng} color={value.markerColor || "green"} />
          </Suspense>
        ))}
    </>
  );
};
