import { LatLng } from "leaflet";
import { UseQueryOptions, useQueries, useQuery } from "react-query";
import { getRasterData } from "../services/guppy.service";
import { MyMapClick, ScoreResult, SpatialOverlayer } from "../types";
import { RasterQuantiles } from "guppy.ts";
import { positionOnLegend } from "../utils/legend.utils";
import { isDefined } from "../utils/TypeUtils";

export function useRasterData(
  layer: string | null,
  latLng: LatLng,
  options?: UseQueryOptions<number | undefined, Error>
) {
  return useQuery<number | undefined, Error>(["rasterData", layer, latLng], () => getRasterData(layer, latLng), {
    ...options,
    retry: false,
  });
}

export function useMultipleRasterData(
  markers: MyMapClick[],
  layer: SpatialOverlayer,
  quantiles: RasterQuantiles[],
  enabled: boolean = true
) {
  const rasterDataQueries = markers.map((marker) => ({
    queryKey: ["rasterData", "graph", layer.wmsLayer.layers, marker.latLng],
    queryFn: async () => {
      const data = await getRasterData(layer.wmsLayer.layers, marker.latLng);

      return { data, latLng: marker.latLng };
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
    enabled,
  }));
  const queryResults = useQueries(rasterDataQueries);
  const rasterData: ScoreResult[] = queryResults
    .map((result) => {
      if (result.isError || result.data === undefined) return null;

      if (result.data.data === undefined || result.data.data === null) {
        console.log("So we here... ? ", result);
        return {
          value: null,
          latLng: result.data.latLng,
          mappedValue: null,
        };
      }

      console.log("This is the result: ", result, "This si the result data data:", result.data);

      return {
        value: result.data.data,
        latLng: result.data.latLng,
        mappedValue: positionOnLegend(quantiles, result.data.data, 100),
      };
    })
    .filter(isDefined);
  return rasterData;
}

// export function useMultipleMarkerFeatureInfo(markers: MyMapClick[], layer: string, url: string, enabled = true) {
//   const featureInfoQueries = markers.map((marker) => ({
//     queryKey: ["feature-info", layer, url, marker.latLng],
//     queryFn: () => getFeatureInfo(layer, url, marker),
//     cacheTime: Infinity,
//     staleTime: Infinity,
//     enabled,
//   }));
//   const queryResults = useQueries(featureInfoQueries);
//   const features = queryResults
//     .filter((result) => !result.isError)
//     .map((result) => result.data)
//     .filter(isDefined);
//   return features;
// }
