import React, { MutableRefObject, Suspense, useEffect } from "react";
import { useAtom } from "jotai";
import styled from "styled-components";
import { Route } from "react-router";
import L from "leaflet";
import { AttributionControl, MapContainer } from "react-leaflet";
import { MapHandleClick } from "../components/map/MapHandleClick";
import { MapSizeObserver } from "../components/map/MapSizeObserver";
import { MapWalkingDistance } from "../components/map/MapWalkingDistance";
import { OverlayerWMS } from "../components/map/MapTileLayers";
import { MapAnalysis } from "../components/map/MapAnalysis";
import { MapMarkers } from "../components/map/MapMarkers";
import { MapScale } from "../components/map/MapScale";

import { Spinner } from "../components/Spinner";
import { Actionbar } from "../components/Actionbar";
import { AnalysePane, ZoneDistancePane } from "../components/panes";
import { activeSpatialOverlayerAtom } from "../atoms/overlayers.atom";
import { BaselayerOnMap } from "../components/map/BaselayerOnMap";
import { DemographicOverlayerLegend } from "../components/map/ExtraOverlayerLegend";
import { MapZoomControls } from "../components/map/MapZoomControls";
import { SpatialOverlayerLegend } from "../components/map/SpatialOverlayerLegend";
import { useMapPosition } from "../hooks/useMapPosition";
import { PersistMapPosition } from "../components/map/PersistMapPosition";
import { ExtraOverlayersPane } from "../components/panes/ExtraOverlayersPane";
import { MapExtraLayerLegend } from "../components/map/MapExtraLayerLegend";
import { FoodSwampsPane } from "../components/panes/FoodSwamsPane";

interface Props {
  actionBarContainer: MutableRefObject<HTMLDivElement | null>;
  paneContainer: MutableRefObject<HTMLDivElement | null>;
}

export const DashboardPage = (props: Props) => {
  const { actionBarContainer, paneContainer } = props;

  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const position = useMapPosition();

  useEffect(() => {
    if (divRef.current) {
      L.DomEvent.disableClickPropagation(divRef.current);
    }
  });

  return (
    <StyledMapContainer zoom={position.zoom} center={position} zoomControl={false} attributionControl={false}>
      <PersistMapPosition />
      <BaselayerOnMap />

      <Suspense fallback={<Spinner />}>
        <OverlayerWMS />
      </Suspense>

      <MapSizeObserver />
      <MapHandleClick />
      <AttributionControl position="bottomright" />
      <MapScale />

      <div ref={divRef}>
        <Suspense fallback={<Spinner />}>
          {activeSpatialOverlayer && <MapAnalysis />}
          <MapZoomControls position="bottomright" />
          <SpatialOverlayerLegend />
          <DemographicOverlayerLegend />
        </Suspense>
      </div>

      <Route path="/zone">
        <Suspense fallback={<Spinner />}>
          <MapWalkingDistance />
        </Suspense>
      </Route>

      <MapMarkers />
      <MapExtraLayerLegend />

      <ExtraOverlayersPane paneContainer={paneContainer} />
      <AnalysePane paneContainer={paneContainer} />
      <ZoneDistancePane paneContainer={paneContainer} />
      <Actionbar actionBarContainer={actionBarContainer} />
      <FoodSwampsPane paneContainer={paneContainer} />
    </StyledMapContainer>
  );
};

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;

  .leaflet-control-container {
    display: flex;
    position: relative;
    flex: 0 0 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid blue;
  }

  // target 4 corners
  .leaflet-top,
  .leaflet-bottom {
    position: relative;
    display: flex;
    flex: 0 1 50%;
    flex-direction: column;
    overflow: hidden;
  }

  .leaflet-top.leaflet-right {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .leaflet-bottom.leaflet-left {
    align-self: flex-end;
  }

  .leaflet-bottom.leaflet-right {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .marker-icon {
    position: absolute;
    margin-top: -30px;
    margin-left: -8px;

    path {
      stroke: white;
      stroke-width: 1px;
      filter: drop-shadow(0px 60px 30px rgba(0, 0, 0, 0.6));
    }
  }
`;
