import { Suspense } from "react";
import { useAtom } from "jotai";
import { activeBelfiusOverlayerAtom, activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { boundsAtom } from "../../atoms/bounds.atom";
import { foodSwampOverlayersAtom } from "../../atoms/foodSwampOverlayers.atom";
import { MatrixQuantiles } from "./MatrixQuantiles";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

export function MatrixAnalysis() {
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);
  const [foodswampOverlayers] = useAtom(foodSwampOverlayersAtom);
  const foodswampLayer = foodswampOverlayers.find((layer) => layer.key === "moerasscore");
  const [isLegendRescaled] = useAtom(isLegendRescaledAtom);
  const [bounds] = useAtom(boundsAtom);

  return (
    <Suspense fallback={<></>}>
      <Styles>
        <Typography variant="h4">Matrix</Typography>
        <Typography>Uitleg over de grafiek</Typography>
        {activeSpatialOverlayer && foodswampLayer && bounds && (
          <MatrixQuantiles
            layer={activeSpatialOverlayer}
            belfiusLayer={activeBelfiusOverlayer}
            foodswampLayer={foodswampLayer}
            isLegendRescaled={isLegendRescaled}
            bounds={bounds}
          />
        )}
      </Styles>
    </Suspense>
  );
}

const Styles = styled("div")`
  margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
