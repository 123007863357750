import { FeatureCollection } from "geojson";

export function parseXML(input: string, contentType: DOMParserSupportedType = "application/xml"): Document | null {
  if ("DOMParser" in window) {
    try {
      const parser = new DOMParser();
      return parser.parseFromString(input, contentType);
    } catch (err) {
      console.error("XML parser:", err);
      return null;
    }
  }

  return null;
}

export function parseToCollection(xml: Document, x: number, y: number): FeatureCollection | "" {
  const fields = xml.getElementsByTagName("FIELDS");
  const { length } = fields;
  if (length === 0) return "";
  const properties: { [x: string]: string | number | boolean | null } = {};
  for (let i = 0; i < length; i++) {
    const field = fields.item(i);
    if (field) {
      const attrs = field.getAttributeNames();
      attrs.forEach((name) => {
        properties[name] = field.getAttribute(name);
      });
    }
  }

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        id: "parsed-xml",
        geometry: {
          type: "Point",
          coordinates: [x, y],
        },
        properties: { ...properties },
      },
    ],
  };
}
