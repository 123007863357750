import { Dialog, DialogActions, DialogTitle, Paper, PaperProps } from "@material-ui/core";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { ArrowForwardIosSharp as ArrowForwardIosSharpIcon } from "@material-ui/icons";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import Draggable from "react-draggable";

import { HistoryState } from "../types";
import { DemographicInformation } from "./information/DemographicInformation";
import { WalkabilityIndicatorInformation } from "./information/WalkabilityIndicatorInformation";
import { BelfiusClustersInformation } from "./information/BelfiusClustersInformation";
import { WalkabilityLegendInformation } from "./information/WalkabilityLegendInformation";
import { AnalyseInformation } from "./information/AnalyseInformation";
import { ExtraOverlayersInformation } from "./information/ExtraOverlayersInformation";

export type InformationSubject =
  | false
  | "walkabilityscore"
  | "demographic"
  | "belfius-clusters"
  | "walkability-legend"
  | "analyse"
  | "extra-overlayers"
  | "food-swamps";

interface Props {}

export function InformationDialog(props: Props) {
  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();

  function handleClose() {
    history.replace(`${location.pathname}${location.search}`, { informationDialogSubject: undefined });
  }

  return render();

  function render() {
    if (!location?.state?.informationDialogSubject) return null;

    return (
      <StyledDialog
        PaperComponent={PaperComponent}
        open={Boolean(location.state.informationDialogSubject)}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        {renderBody()}
        <DialogActions />
      </StyledDialog>
    );
  }

  function renderBody() {
    switch (location.state.informationDialogSubject) {
      case "walkabilityscore":
        return <WalkabilityIndicatorInformation />;

      case "demographic":
        return <DemographicInformation />;

      case "belfius-clusters":
        return <BelfiusClustersInformation />;

      case "walkability-legend":
        return <WalkabilityLegendInformation />;

      case "analyse":
        return <AnalyseInformation />;

      case "extra-overlayers":
        return <ExtraOverlayersInformation />;

      default:
        return null;
    }
  }
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: fit-content;
    max-height: calc(100vh - ${({ theme }) => theme.spacing(2)}px);
  }

  img {
    width: fit-content;
    max-width: 100%;
  }
`;

export const Accordion = styled(MuiAccordion).attrs((props: AccordionProps) => ({
  elevation: 0,
  square: true,
  ...props,
}))`
  border: 1px solid ${({ theme }) => theme.palette.divider};

  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary).attrs((props: AccordionSummaryProps) => ({
  expandIcon: <ArrowForwardIosSharpIcon style={{ fontSize: "0.9rem" }} />,
  ...props,
}))`
  padding: 0;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)"};
  flex-direction: row-reverse;

  & .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-content {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  flex-direction: column;

  ol {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    padding: ${({ theme }) => theme.spacing(0, 0, 1, 2)};
  }
`;

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const InformationDialogTitle = styled(DialogTitle).attrs({
  id: "draggable-dialog-title",
})`
  .MuiTypography-h6 {
    cursor: move;
  }
`;
