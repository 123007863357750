import { LatLng } from "leaflet";
import { useStatisticalSectorShape } from "../../hooks/analyseHooks";
import { GeoJSON } from "react-leaflet";

interface Props {
  latLng: LatLng;
  color: string;
}
export function RenderStatisticalSectorGeojson(props: Props) {
  const { latLng, color } = props;
  const { data: feature } = useStatisticalSectorShape(latLng);

  return (
    <>
      {feature && (
        <GeoJSON
          key="polygon-layer"
          data={feature}
          style={{
            color: "white",
            fillColor: color,
            fillOpacity: 0.5,
            opacity: 4,
            weight: 2,
          }}
        ></GeoJSON>
      )}
    </>
  );
}
