import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from "recharts";
import { RasterQuantiles } from "guppy.ts";
import { useGraphTicks } from "../../hooks/foodswampMatrixHooks";
import { MatrixGraphData, TickMapping } from "../../types";
import { round } from "lodash-es";

interface Props {
  foodswampQuantiles: RasterQuantiles[];
  walkscoreQuantiles: RasterQuantiles[];
  data: MatrixGraphData[];
}

export function MatrixGraph(props: Props) {
  const { foodswampQuantiles, data, walkscoreQuantiles } = props;

  function formatTick(tick: number, mapping: TickMapping) {
    return mapping[tick] || "";
  }

  const { yTickMapping, yTicks, xTickMapping, xTicks } = useGraphTicks({
    xAxisQuantiles: foodswampQuantiles,
    yAxisQuantiles: walkscoreQuantiles,
  });

  return (
    <ScatterChart width={300} height={300} margin={{ top: 20, bottom: 40, right: 20 }}>
      <CartesianGrid stroke="rgb(102, 102, 102)" strokeWidth={1} />
      <XAxis
        type="number"
        dataKey="foodswamp"
        name="Moerasscore"
        domain={[0, 100]}
        tickLine={false}
        interval={0}
        tickFormatter={(tick: number) => formatTick(tick, xTickMapping)}
        ticks={xTicks}
        label={{ value: "Moerasscore", position: "insideBottom", offset: -15 }}
      />
      <YAxis
        type="number"
        dataKey="walkscore"
        name="Walkscore"
        domain={[0, 100]}
        ticks={yTicks}
        tickFormatter={(tick: number) => formatTick(tick, yTickMapping)}
        tickLine={false}
        interval={0}
        label={{ value: "Walkscore", angle: -90, position: "insideLeft", offset: 5 }}
      />
      <Tooltip
        cursor={{ strokeDasharray: "3 3" }}
        formatter={(_value, _label, data) => {
          console.log("c", data);
          const key = `${data.dataKey}RawValue`;
          const value: number = data.payload[key];
          return round(value, 2);
        }}
      />
      <Scatter data={data}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Scatter>
    </ScatterChart>
  );
}

export function CustomTooltip(props: any) {}
