import { useHistory, useLocation } from "react-router";

export function useUpdateSearchParams() {
  const location = useLocation();
  const history = useHistory();

  function update(key: string, value: string | string[]) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);

    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(key, item);
      });
    } else {
      searchParams.set(key, value);
    }

    history.push({
      search: searchParams.toString(),
    });
  }

  return update;
}
