import { RasterQuantiles } from "guppy.ts";
import { LatLng } from "leaflet";
import styled from "styled-components";
import { useRasterData } from "../hooks";
import { BaseOverlayer } from "../types";
import { positionOnLegend } from "../utils/legend.utils";
import { useAnalysisType, useFeatureInfo } from "../hooks/analyseHooks";

interface Props {
  latLng: LatLng;
  layer: BaseOverlayer;
  quantiles: RasterQuantiles[];
  color: string;
  gradientWidth: number;
  isShapeFile: boolean;
  attributeKey?: string;
}

export const MarkerOnGradient = (props: Props) => {
  const { latLng, layer, color, isShapeFile, attributeKey } = props;
  const { analysisType } = useAnalysisType();

  const showRhombus = analysisType === "statSec";

  const { data: score } = useRasterData(layer.wmsLayer.layers, latLng, {
    enabled: isShapeFile === false,
  });

  const { data: feature } = useFeatureInfo(layer.wmsLayer.layers, layer.wmsLayer.url, latLng, {
    enabled: isShapeFile,
  });

  const value = feature?.properties && attributeKey ? feature?.properties[attributeKey] : score;

  return render();

  function render() {
    if (!value) return null;

    return (
      <Styles
        leftDistance={positionOnLegend(props.quantiles, value, props.gradientWidth)}
        color={color}
        className={showRhombus ? "rhombus" : ""}
      ></Styles>
    );
  }
};

const Styles = styled.div<{
  leftDistance: number;
  color?: string;
}>`
  position: absolute;
  left: ${({ leftDistance }) => `${leftDistance}px`};
  margin-top: 10px;
  width: 1px;
  height: 22px;
  background: ${({ color }) => (color ? color : "black")};

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${({ color }) => (color ? color : "black")};
    bottom: -8px;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  &.rhombus:after {
    transform: translateX(-50%) rotate(45deg);
    border-radius: 0;
  }
`;
