import { ExtraInfoOverlayer } from "../../types";

export const CATEGORY_ORDER: Array<ExtraInfoOverlayer["category"]> = [
  "gemeentegrenzen",
  "voorzieningen",
  "mobiliteit",
  "synthesekaarten",
];

export const extraInfoOverlayers: ExtraInfoOverlayer[] = [
  // Gemeentegrenzen
  {
    key: "gemeentegrenzen_vlaanderen_Refgem",
    name: "Gemeentegrenzen Vlaanderen",
    isActive: false,
    wmsLayer: {
      url: "https://geo.api.vlaanderen.be/Administratieve_Eenheden/wms",
      layers: "Refgem",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "default",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/9ff44cc4-5f16-4507-81a6-6810958b14df" rel="noopener noreferrer" target="_blank">Informatie Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "gemeentegrenzen",
  },
  {
    key: "PERSPECTIVE_FR:CARTE_PRDD_02_POLES_DE_DEVELOPPEMENT_PRIORITAIRE",
    name: "Gemeentegrenzen Brussel (BHG)",
    isActive: false,
    wmsLayer: {
      url: "https://geoservices-urbis.irisnet.be/geoserver/wms",
      layers: "UrbisAdm:Mu",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://geobru.irisnet.be/nl/webservices/" rel="noopener noreferrer" target="_blank">Geo Brussels</a>`,
      zIndex: 9,
    },
    category: "gemeentegrenzen",
  },

  // Mobiliteit
  {
    key: "beleid:bff",
    name: "Fietsroutenetwerk",
    isActive: false,
    wmsLayer: {
      url: "https://geoserver.gis.cloud.mow.vlaanderen.be/geoserver/wms",
      layers: "beleid:bff",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution:
        '<a href="https://www.geopunt.be/catalogus/datasetfolder/cdf0ec2d-69d2-49cd-8f35-596be010745e" rel="noopener noreferrer" target="_blank">Departement Mobiliteit en Openbare Werken, afdeling Beleid, Bovenlokaal Functioneel Fietsroutenetwerk Ligging (BFF) </a>',
      zIndex: 9,
    },
    category: "mobiliteit",
  },
  {
    key: "walkability:Knooppuntwaarde_2019",
    name: "Haltes De Lijn",
    isActive: false,
    wmsLayer: {
      url: "https://geo.api.vlaanderen.be/DeLijn/wms",
      layers: "Halte",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "default",
      attribution: `<a href="https://www.geopunt.be/catalogus/webservicefolder/dc149f15-51bf-4c00-38d5-5eae-b739-8893-3af5aea0" rel="noopener noreferrer" target="_blank">De Lijn via Geopunt</a>`,
      zIndex: 10,
    },
    category: "mobiliteit",
  },
  {
    key: "stib_stops",
    name: "Haltes MIVB",
    isActive: false,
    wmsLayer: {
      url: "https://data.mobility.brussels/geoserver/bm_public_transport/wms",
      layers: "stib_stops",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://data-mobility.irisnet.be/nl/info/stib_stops/" rel="noopener noreferrer" target="_blank">Brussels Hoofdstedelijk Gewest mobiliteit - Gewestelijke overheidsdienst Brussel</a>`,
      zIndex: 9,
    },
    category: "mobiliteit",
  },
  {
    key: "walkability:Knooppuntwaarde_2019_2",
    name: "Knooppuntwaarden 2019",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:Knooppuntwaarde_2019",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: "Knooppuntwaarden 2019",
      zIndex: 9,
    },
    category: "mobiliteit",
  },
  {
    key: "walkability-v2:veren",
    name: "Veren niet erkend als weg (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:veren",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution:
        '<a href="https://overheid.vlaanderen.be/informatie-vlaanderen/producten-diensten/wegenregister" rel="noopener noreferrer" target="_blank">Agentschap informatie Vlaanderen, Wegenregister (versie maart 2019) </a>',
      zIndex: 9,
    },
    category: "mobiliteit",
  },

  // Voorzieningen
  {
    key: "walkability:kinderopvang",
    name: "Kinderopvang (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:kinderopvang",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "walkability:kinderopvang",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/928a7f14-fdef-4ed9-ad94-2dce0b20fbc5" rel="noopener noreferrer" target="_blank">Kinderopvangvoorzieningen erkend door Kind en Gezin, 19-09-2019, Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "voorzieningen",
  },
  {
    key: "walkability:basisonderwijs",
    name: "Basisscholen (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:basisonderwijs",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/b2027867-cd3e-4e67-b6f1-aec3ff878f44" rel="noopener noreferrer" target="_blank">Onderwijsaanbod in Vlaanderen, 06-11-2019, Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "voorzieningen",
  },
  {
    key: "walkability:secundaironderwijs",
    name: "Secundaire scholen (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:secundaironderwijs",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/b2027867-cd3e-4e67-b6f1-aec3ff878f44" rel="noopener noreferrer" target="_blank">Onderwijsaanbod in Vlaanderen, 06-11-2019, Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "voorzieningen",
  },
  {
    key: "walkability:ziekenhuis",
    name: "Ziekenhuizen (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:ziekenhuis",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/9531f406-ca7c-4987-9e8b-37de471a57b3" rel="noopener noreferrer" target="_blank">Zorgvoorzieningen erkend door het Vlaams Agentschap Zorg en Gezondheid, 17-09-2019, Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "voorzieningen",
  },
  {
    key: "walkability:assistentiewoningen",
    name: "Assistentiewoningen (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:assistentiewoningen",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/9531f406-ca7c-4987-9e8b-37de471a57b3" rel="noopener noreferrer" target="_blank">Zorgvoorzieningen erkend door het Vlaams Agentschap Zorg en Gezondheid, 17-09-2019, Vlaanderen</a>`,
      zIndex: 9,
    },
    category: "voorzieningen",
  },
  {
    key: "walkability:woonzorgcentrum",
    name: "Woonzorgcentra (2019)",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability:woonzorgcentrum",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "",
      attribution: `<a href="https://www.geopunt.be/catalogus/datasetfolder/9531f406-ca7c-4987-9e8b-37de471a57b3" rel="noopener noreferrer" target="_blank">Zorgvoorzieningen erkend door het Vlaams Agentschap Zorg en Gezondheid, 17-09-2019, Vlaanderen</a>`,
    },
    category: "voorzieningen",
  },

  // Synthesekaarten
  {
    key: "lu:lu_synkwvz_ha_2019",
    name: "Ontwikkelingskansen",
    isActive: false,
    wmsLayer: {
      url: "https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/wms",
      layers: "lu:lu_synkwvz_ha_2019",
      format: "image/png",
      transparent: true,
      tiled: true,
      styles: "lu_synkwvz_ha_xxxx_std",
      attribution:
        '<a href="https://www.geopunt.be/catalogus/datasetfolder/dfe33af8-c42b-4854-8fb0-278f4f7ff99a" rel="noopener noreferrer" target="_blank">Departement Omgeving, Synthese knooppuntwaarde en voorzieningenniveau - toestand 2015 - mét A-buslijnen-klassering via natural breaks </a>',
      zIndex: 9,
    },
    category: "synthesekaarten",
  },
  {
    key: "StatSec",
    name: "Statistische sectoren",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:Statsec",
      format: "image/png",
      transparent: true,
      tiled: true,
      zIndex: 9,
    },
    category: "statistische sectoren",
  },
];
