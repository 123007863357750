import { Getter, Setter, atom } from "jotai";
import { BaseOverlayer } from "../types";
import { foodswamOverlayers } from "../config/overlayers/foodswamp-overlayers";

export const foodSwampOverlayersAtom = atom<BaseOverlayer[]>(foodswamOverlayers);
export const activeFoodSwampOverlayerAtom = atom((get) => get(foodSwampOverlayersAtom).find((o) => o.isActive));
export const updateFoodSwampOverlayerAtom = atom(
  null,
  (get: Getter, set: Setter, update: BaseOverlayer["key"] | null) => {
    set(
      foodSwampOverlayersAtom,
      get(foodSwampOverlayersAtom).map((overlayer) => {
        if (overlayer.key === update) return { ...overlayer, isActive: true };
        else return { ...overlayer, isActive: false };
      })
    );
  }
);
