import { memo } from "react";
import { WMSTileLayer, WMSTileLayerProps } from "react-leaflet";

interface Props {
  zIndex: number;
  wmsLayer: {
    tiled?: boolean;
    cql_filter?: string;
    layers: string;
  } & WMSTileLayerProps;
}

export const RenderWMSTileLayer = memo((props: Props) => {
  const { wmsLayer, zIndex } = props;
  return <WMSTileLayer {...wmsLayer} zIndex={zIndex} />;
});
