import axios, { AxiosResponse } from "axios";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { parseToCollection, parseXML } from "../utils/XMLUtils";

export interface WMSFeatureInfoParams {
  x: number;
  y: number;
  width: number;
  height: number;
  bbox: string;
  layers: string;
  query_layers: string;
  buffer?: number;
  version?: string;
  request?: string;
  service?: string;
  srs?: string;
  info_format?: string;
  viewParams?: string;
}

const VALID_XML_TYPES: DOMParserSupportedType[] = [
  "application/xhtml+xml",
  "application/xml",
  "image/svg+xml",
  "text/html",
  "text/xml",
];

export function getWMSFeatureInfoCoordinates(latlng: { lat: number; lng: number }) {
  const bboxPrecision = 0.01;
  const bbox = [
    latlng.lng - bboxPrecision,
    latlng.lat - bboxPrecision,
    latlng.lng + bboxPrecision,
    latlng.lat + bboxPrecision,
  ].join(",");

  const params = {
    x: 50,
    y: 50,
    width: 101,
    height: 101,
    bbox,
  };
  return params;
}

function getFeautureInfoXmlCheck(
  response: AxiosResponse<string | FeatureCollection | undefined>,
  x: number,
  y: number
) {
  const { data, headers } = response;
  const contentType = VALID_XML_TYPES.find((type) => type === headers["content-type"]);
  if (
    typeof data === "string" &&
    headers &&
    "content-type" in headers &&
    (contentType || headers["content-type"] === "application/vnd.ogc.se_xml;charset=UTF-8")
  ) {
    const xml = parseXML(data, contentType);
    if (xml) return parseToCollection(xml, x, y);
  }

  if (typeof data === "string") {
    return "";
  }

  return data;
}

export async function getWMSFeatureInfo(
  customParams: WMSFeatureInfoParams,
  url: string = "/geoserver/wms"
): Promise<FeatureCollection<Geometry, GeoJsonProperties> | null> {
  const defaultParams: Partial<WMSFeatureInfoParams> = {
    version: "1.1.1",
    request: "GetFeatureInfo",
    service: "WMS",
    srs: "EPSG:4326",
    buffer: 5,
    info_format: "application/json",
  };

  const config = {
    params: { ...defaultParams, ...customParams },
  };

  try {
    const response = await axios.get(url, config);
    const data = getFeautureInfoXmlCheck(response, customParams.x, customParams.y);
    if (typeof data === "string" || !data) return null;
    return data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}

export interface WFSGetFeatureParams {
  typeNames?: string;
  cql_filter?: string;
  viewParams?: string;
  featureCount?: number;
  request?: string;
  service?: string;
  version?: string;
  outputFormat?: string;
  srsName?: string;
}

export async function getWFSGetFeature<P>(
  customParams: WFSGetFeatureParams
): Promise<FeatureCollection<Geometry, P & GeoJsonProperties>> {
  const defaultParams: Partial<WFSGetFeatureParams> = {
    featureCount: 100,
    request: "GetFeature",
    service: "WFS",
    version: "2.0",
    outputFormat: "application/json",
    srsName: "EPSG:4326",
  };
  const config = {
    params: { ...defaultParams, ...customParams },
  };

  try {
    const { data } = await axios.get(`/geoserver/wfs`, config);
    return data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
