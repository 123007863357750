import { LeafletMouseEvent } from "leaflet";
import { useMap, useMapEvent } from "react-leaflet";
import { useParams } from "react-router";
import { useMarkers } from "../../hooks";
import { MyMapClick } from "../../types";
import { getMapClick } from "../../utils/map.utils";
import { useUpdateSearchParams } from "../../hooks/routerHooks";

export const MapHandleClick = () => {
  const mapRef = useMap();
  const markers = useMarkers();
  const { activeAction } = useParams<{ activeAction: string }>();

  const update = useUpdateSearchParams();

  function onClick(event: LeafletMouseEvent) {
    const mapClick = getMapClick(event, mapRef);
    const markerArray = getMarkerArray(activeAction, markers, mapClick);
    update(
      "markers",
      markerArray.map((marker) => marker.latLng.lat + "," + marker.latLng.lng)
    );
  }

  useMapEvent("click", onClick);

  return null;
};
// only allow multiple markers when on /analyse route
function getMarkerArray(action: string, markers: MyMapClick[], mapClick: MyMapClick) {
  if (action === "analyse" || action === "food-swamps") {
    return [...markers, mapClick];
  }
  return [mapClick];
}
