import { MutableRefObject } from "react";
import { useAtom } from "jotai";
import { Drawer, Portal, Toolbar, Typography, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import styled from "styled-components";
import { useParams } from "react-router";

import { PanePaper } from "../surface/PaperBackground";
import {
  activeFoodSwampOverlayerAtom,
  foodSwampOverlayersAtom,
  updateFoodSwampOverlayerAtom,
} from "../../atoms/foodSwampOverlayers.atom";
import { MatrixAnalysis } from "../chart/MatrixAnalysis";

interface Props {
  paneContainer: MutableRefObject<HTMLDivElement | null>;
}

export const FoodSwampsPane = (props: Props) => {
  const { paneContainer } = props;

  const { activeAction } = useParams<{ activeAction: string }>();
  const [activeFoodSwampOverlayer] = useAtom(activeFoodSwampOverlayerAtom);

  const [foodSwampOverlayers] = useAtom(foodSwampOverlayersAtom);
  const [, setActiveFoodswampOverlayer] = useAtom(updateFoodSwampOverlayerAtom);

  /**
   * Functions
   */
  function updateExtraInfoOverlayers(layerKey: string) {
    setActiveFoodswampOverlayer(layerKey);
  }

  //   function showMoreInformation() {
  //     history.push(location.pathname, { informationDialogSubject: "food-swamps" });
  //   }

  return render();

  function render() {
    if (activeAction !== "food-swamps") return null;

    return (
      <Portal container={paneContainer.current}>
        <StyledDrawer
          style={{ zIndex: 9999 }}
          variant="persistent"
          open={true}
          elevation={12}
          anchor="right"
          PaperProps={{ component: PanePaper }}
        >
          <Toolbar />
          <Typography variant="h3">
            Voedselomgevingen
            {/* <IconButton onClick={showMoreInformation}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton> */}
          </Typography>
          <Typography gutterBottom>
            Via deze functie wordt de (fysieke) voedselomgeving voor de statistische sectoren in Vlaanderen in kaart
            gebracht. Hiervoor wordt gewerkt met de concepten voedselwoestijnen en voedselmoerassen.
          </Typography>
          <Typography gutterBottom>
            <strong>De Voedselmoeras Index</strong> geeft een score tussen 0 en 1. Hoe lager de score, hoe ongezonder of
            ‘dieper’ het voedselmoeras is en hoe vaker de inwoners van een buurt in contact komen met ongezonde in
            vergelijking met gezonde voeding.
          </Typography>
          <Typography gutterBottom>
            <strong>Een voedselwoestijn</strong> wordt gedefinieerd als een residentiële buurt waar geen gemakkelijke
            toegang is tot alle voeding die nodig is voor een gezond voedingspatroon.
          </Typography>
          <Typography gutterBottom>
            Duid hieronder aan of je de kaart van de voedselmoeras index wilt openen of deze van de voedselwoestijn.
          </Typography>

          <RadioGroup
            value={activeFoodSwampOverlayer?.key || ""}
            onChange={(_e, val) => {
              updateExtraInfoOverlayers(val);
            }}
          >
            <FormControlLabel value="" control={<Radio color="primary" />} label="Geen kaart" />
            {foodSwampOverlayers.map((layer) => (
              <FormControlLabel
                key={layer.key}
                value={layer.key}
                control={<Radio color="primary" onClick={() => updateExtraInfoOverlayers(layer.key)} />}
                label={layer.name}
              />
            ))}
          </RadioGroup>

          <MatrixAnalysis />
        </StyledDrawer>
      </Portal>
    );
  }
};

const StyledDrawer = styled(Drawer)`
  width: 330px;
  overflow: visible;
  .MuiTypography-h3 {
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
  .MuiTypography-gutterBottom {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;
