import { Suspense, useState } from "react";
import { MapControl } from "marvin-ui-kit";
import styled from "styled-components";
import { Fab, FormControlLabel, Grid, IconButton, Switch } from "@material-ui/core";
import {
  Close as CloseIcon,
  List as ListIcon,
  Refresh as RefreshIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useMap } from "react-leaflet";
import { useAtom } from "jotai";

import { MapWindowPaper } from "../surface/PaperBackground";
import { CustomMapControlBase } from "./CustomMapControlBase";
import { MapIntervalLegend } from "./MapIntervalLegend";
import { Spacer } from "../Spacer";
import { boundsAtom } from "../../atoms/bounds.atom";
import { activeBelfiusOverlayerAtom, activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { BoxHeader } from "../BoxHeader";
import { useHistory, useLocation } from "react-router";
import { HistoryState } from "../../types";

interface Props {}

export function SpatialOverlayerLegend(props: Props) {
  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();

  const map = useMap();
  const [, setBounds] = useAtom(boundsAtom);
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);

  const [isLegendRescaled, setIsLegendRescaled] = useAtom(isLegendRescaledAtom);
  const [showLegend, setShowLegend] = useState<boolean>(true);

  function toggleLegend() {
    setShowLegend(!showLegend);
  }

  function showMoreInformation() {
    history.push(location.pathname, { informationDialogSubject: "walkability-legend" });
  }

  return render();

  function render() {
    if (!activeSpatialOverlayer) return null;
    if (activeSpatialOverlayer.isShapeFile) return null;

    return (
      <CustomMapControlBase position="bottomleft">
        <Styles>
          {showLegend && renderOpen()}
          {!showLegend && renderClosed()}
        </Styles>
      </CustomMapControlBase>
    );
  }

  function renderOpen() {
    if (!activeSpatialOverlayer) return null;

    return (
      <MapControl className="container-open">
        <BoxHeader>
          Legende
          <IconButton onClick={showMoreInformation}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
          <Spacer />
          <FormControlLabel
            control={
              <>
                {/* a belfius cluster ix fixed and can't be rescaled */}
                {!activeBelfiusOverlayer && (
                  <IconButton onClick={() => setBounds(map.getBounds())}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                )}
                <Switch
                  checked={isLegendRescaled}
                  value={isLegendRescaled}
                  onChange={() => {
                    // if new checked is true, set new bounds
                    if (!isLegendRescaled === true) {
                      setBounds(map.getBounds());
                    }

                    setIsLegendRescaled(!isLegendRescaled);
                  }}
                  color="secondary"
                  size="small"
                />
              </>
            }
            label="Herschaal"
            labelPlacement="start"
          />
          <IconButton onClick={toggleLegend}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </BoxHeader>
        <Grid container component={MapWindowPaper} square direction="row">
          {renderLegend()}
        </Grid>
      </MapControl>
    );
  }

  function renderClosed() {
    return (
      <Fab onClick={toggleLegend} size="medium" color="secondary">
        <ListIcon fontSize="small" />
      </Fab>
    );
  }
  function renderLegend() {
    if (!activeSpatialOverlayer) return null;

    return (
      <Suspense fallback={<LegendLoader />}>
        <MapIntervalLegend overlayer={activeSpatialOverlayer} />
      </Suspense>
    );
  }
}

function LegendLoader() {
  return <Skeleton variant="rect" width={291} height={232} />;
}

const Styles = styled.div`
  width: 100%;
  overflow: auto;

  .container-open {
    width: 370px;
  }
`;
