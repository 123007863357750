import { Suspense } from "react";
import { LatLngBounds } from "leaflet";
import { BaseOverlayer, BelfiusOverlayer, SpatialOverlayer } from "../../types";
import { latLngBoundsToWkt } from "../../utils/geometry.utils";
import { BELGIUM_GEOMETRY, QUANTILE_BINS } from "../../config";
import { useRaster } from "../../hooks/useRaster";
import { useRasterQuantiles } from "../../hooks";
import { useBelfiusQuantiles } from "../../hooks/useBelfiusQuantiles";
import { baseQuantiles } from "../../config/quantiles";
import { RasterQuantiles } from "guppy.ts";
import { quantilesWithAbsoluteMinMax } from "../../utils/quantile.utils";
import { MatrixData } from "./MatrixData";

interface Props {
  layer: SpatialOverlayer;
  belfiusLayer?: BelfiusOverlayer;
  bounds: LatLngBounds;
  isLegendRescaled: boolean;
  foodswampLayer: BaseOverlayer;
}

export function MatrixQuantiles(props: Props) {
  const { layer, bounds, isLegendRescaled, belfiusLayer, foodswampLayer } = props;

  const geometry = bounds && isLegendRescaled ? latLngBoundsToWkt(bounds) || BELGIUM_GEOMETRY : BELGIUM_GEOMETRY;
  const { data: rasterDetails } = useRaster(layer.wmsLayer.layers, {
    enabled: !layer.isShapeFile,
  });

  const { data: quantilesBelgium } = useRasterQuantiles(layer, QUANTILE_BINS, geometry, {
    enabled: !layer.isShapeFile,
  });

  const { data: belfiusQuantiles } = useBelfiusQuantiles(belfiusLayer, layer, {
    enabled: !layer.isShapeFile && !!belfiusLayer,
  });

  function getCorrectQuantiles(): RasterQuantiles[] {
    if (layer.isShapeFile) return baseQuantiles;
    if (belfiusLayer) return belfiusQuantiles || baseQuantiles;
    if (quantilesBelgium && isLegendRescaled) return quantilesBelgium;
    if (quantilesBelgium && !isLegendRescaled && rasterDetails)
      return quantilesWithAbsoluteMinMax(rasterDetails, quantilesBelgium);
    return baseQuantiles;
  }
  const quantiles = getCorrectQuantiles();
  return (
    <Suspense fallback={<></>}>
      <MatrixData layer={layer} quantiles={quantiles} foodswampLayer={foodswampLayer} />
    </Suspense>
  );
}
