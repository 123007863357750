import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { MatrixGraphData } from "../../types";
import { Delete, Room } from "@material-ui/icons";
import { useMarkers } from "../../hooks";
import { useUpdateSearchParams } from "../../hooks/routerHooks";
import { round } from "lodash";

interface Props {
  data: MatrixGraphData[];
}

export function MatrixMarkerList(props: Props) {
  const { data } = props;
  const markers = useMarkers();
  const update = useUpdateSearchParams();

  function deleteMarker(index: number) {
    const newMarkers = [...markers];
    newMarkers.splice(index, 1);
    const newMarkerParams = newMarkers.map((marker) => marker.latLng.lat + "," + marker.latLng.lng);
    update("markers", newMarkerParams);
  }

  return (
    <List>
      {data.map((item, index) => (
        <ListItem dense key={item.latLng.toString()}>
          <ListItemIcon>
            <Room className="marker-icon" style={{ fill: item.color }} fontSize="medium" />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography>
                  Walkscore: {item.walkscoreRawValue !== null ? round(item.walkscoreRawValue, 2) : "geen data"}
                </Typography>
                <Typography>
                  Moerasscore: {item.foodswampRawValue !== null ? round(item.foodswampRawValue, 2) : "geen data"}
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              size="small"
              onClick={() => {
                deleteMarker(index);
              }}
            >
              <Delete fontSize="small" style={{ color: "#D1D1D1" }} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
