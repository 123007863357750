import { LatLngBounds } from "leaflet";

import { BelfiusOverlayer, MyMapClick, SpatialOverlayer } from "../../types";
import { BELGIUM_GEOMETRY, QUANTILE_BINS } from "../../config";
import { useRasterQuantiles } from "../../hooks/useRasterQuantiles";
import { useRaster } from "../../hooks/useRaster";
import { useBelfiusQuantiles } from "../../hooks/useBelfiusQuantiles";
import { latLngBoundsToWkt } from "../../utils/geometry.utils";
import { quantilesWithAbsoluteMinMax } from "../../utils/quantile.utils";
import { PunaiseChart } from "../PunaiseChart";
import { baseQuantiles } from "../../config/quantiles";

interface Props {
  bounds: LatLngBounds;
  layer: SpatialOverlayer;
  markers: MyMapClick[];
  belfiusOverlayer: BelfiusOverlayer | undefined;
  isLegendRescaled: boolean;
}

export const ComparisonChart = (props: Props) => {
  return render();
  function render() {
    if (!props.isLegendRescaled) {
      return <ComparisonChartDefault markers={props.markers} layer={props.layer} />;
    } else if (props.belfiusOverlayer) {
      return (
        <ComparisonChartBelfiusCluster
          markers={props.markers}
          layer={props.layer}
          belfiusOverlayer={props.belfiusOverlayer}
        />
      );
    } else {
      return <ComparisonChartBasedOnViewport markers={props.markers} layer={props.layer} bounds={props.bounds} />;
    }
  }
};
function ComparisonChartDefault(props: { markers: MyMapClick[]; layer: SpatialOverlayer }) {
  const { data: rasterDetails } = useRaster(props.layer.wmsLayer.layers, {
    enabled: props.layer.isShapeFile === false ? true : false,
  });
  const { data: quantilesBelgium } = useRasterQuantiles(props.layer, QUANTILE_BINS, BELGIUM_GEOMETRY, {
    enabled: props.layer.isShapeFile === false ? true : false,
  });

  const quantiles =
    rasterDetails && quantilesBelgium ? quantilesWithAbsoluteMinMax(rasterDetails, quantilesBelgium) : baseQuantiles;

  return render();
  function render() {
    return (
      <PunaiseChart
        quantiles={quantiles}
        markers={props.markers}
        layer={props.layer}
        isShapeFile={props.layer.isShapeFile}
      />
    );
  }
}

function ComparisonChartBelfiusCluster(props: {
  markers: MyMapClick[];
  layer: SpatialOverlayer;
  belfiusOverlayer: BelfiusOverlayer;
}) {
  const { data: rasterDetails } = useRaster(props.layer.wmsLayer.layers);
  const { data: quantiles } = useBelfiusQuantiles(props.belfiusOverlayer, props.layer, {});

  return render();
  function render() {
    if (!rasterDetails) return null;
    if (!quantiles) return null;

    return <PunaiseChart quantiles={quantiles} markers={props.markers} layer={props.layer} isShapeFile={false} />;
  }
}

function ComparisonChartBasedOnViewport(props: {
  markers: MyMapClick[];
  layer: SpatialOverlayer;
  bounds: LatLngBounds;
}) {
  const { data: rasterDetails } = useRaster(props.layer.wmsLayer.layers);
  const { data: quantiles } = useRasterQuantiles(props.layer, QUANTILE_BINS, latLngBoundsToWkt(props.bounds) as string);

  return render();
  function render() {
    if (!rasterDetails) return null;
    if (!quantiles) return null;

    return <PunaiseChart quantiles={quantiles} markers={props.markers} layer={props.layer} isShapeFile={false} />;
  }
}
