import { useQueryParam } from "./useQueryParams";
import { AnalysisType, BaseOverlayer, MyMapClick, ScoreResult, SpatialOverlayer } from "../types";
import { useHistory, useLocation } from "react-router";
import { useAtom } from "jotai";
import { extraInfoOverlayersAtom } from "../atoms/overlayers.atom";
import { LatLng } from "leaflet";
import { WMSFeatureInfoParams, getWMSFeatureInfo, getWMSFeatureInfoCoordinates } from "../services/ogc.service";
import { UseQueryOptions, useQueries, useQuery } from "react-query";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { isDefined } from "../utils/TypeUtils";
import { RasterQuantiles } from "guppy.ts";
import { positionOnLegend } from "../utils/legend.utils";

export function useAnalysisType() {
  const analysisTypeFromUrl = useQueryParam("at");
  const history = useHistory();
  const location = useLocation();
  const analysisType: AnalysisType = analysisTypeFromUrl === "statSec" ? "statSec" : "point";

  function updateAnalysisType(analysisType: string) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("at", analysisType);

    history.push({
      search: searchParams.toString(), // Serialize updated searchParams back to string
    });
  }

  return {
    analysisType,
    updateAnalysisType,
  };
}

export function useStatisticalSectorShape(point: LatLng) {
  const [overlayers] = useAtom(extraInfoOverlayersAtom);
  const layer = overlayers.find((o) => o.name === "Statistische sectoren");

  async function fetch() {
    if (!layer) return Promise.resolve(null);
    const wmsFeatureInfoProps = getWMSFeatureInfoCoordinates(point);

    const params: WMSFeatureInfoParams = {
      layers: layer.wmsLayer.layers,
      query_layers: layer.wmsLayer.layers,
      ...wmsFeatureInfoProps,
    };
    const data = await getWMSFeatureInfo(params, layer.wmsLayer.url);
    if (data && data.features.length > 0) return data.features[0];
    return null;
  }

  return useQuery(["statistical-sector-shape", point], fetch, {});
}

export function useFeatureInfo(
  layer: string,
  url: string,
  point: LatLng,
  options?: UseQueryOptions<Feature<Geometry, GeoJsonProperties> | null>
) {
  async function fetch() {
    if (!layer || !url) return Promise.resolve(null);
    const wmsFeatureInfoProps = getWMSFeatureInfoCoordinates(point);
    const params: WMSFeatureInfoParams = {
      layers: layer,
      query_layers: layer,
      ...wmsFeatureInfoProps,
    };
    const data = await getWMSFeatureInfo(params, url);
    if (data && data.features.length > 0) return data.features[0];
    return null;
  }
  return useQuery<Feature<Geometry, GeoJsonProperties> | null>(["feature-info", point, layer], fetch, {
    ...options,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

export function useMultipleMarkerFeatureInfo(markers: MyMapClick[], layer: string, url: string, enabled = true) {
  const featureInfoQueries = markers.map((marker) => ({
    queryKey: ["feature-info", "matrix", layer, url, marker.latLng],
    queryFn: () => getFeatureInfo(layer, url, marker),
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
    enabled,
  }));
  const queryResults = useQueries(featureInfoQueries);
  const features = queryResults
    .filter((result) => !result.isError)
    .map((result) => result.data)
    .filter(isDefined);
  return features;
}

async function getFeatureInfo(layer: string, url: string, marker: MyMapClick) {
  if (!layer || !url) return Promise.resolve(null);
  const wmsFeatureInfoProps = getWMSFeatureInfoCoordinates(marker.latLng);
  const params: WMSFeatureInfoParams = {
    layers: layer,
    query_layers: layer,
    ...wmsFeatureInfoProps,
  };
  const data = await getWMSFeatureInfo(params, url);

  if (data && data.features.length > 0)
    return { feature: data.features[0], color: marker.markerColor || "red", latLng: marker.latLng };
  return null;
}

export function useMultipleMarkerFeatureScores(
  markers: MyMapClick[],
  layer: SpatialOverlayer | BaseOverlayer,
  quantiles: RasterQuantiles[],
  enabled = true
) {
  const features = useMultipleMarkerFeatureInfo(markers, layer.wmsLayer.layers, layer.wmsLayer.url, enabled);
  const featureResults = features.reduce(
    (
      results: ScoreResult[],
      featureWithColor: {
        feature: Feature<Geometry, GeoJsonProperties>;
        color: string;
        latLng: LatLng;
      }
    ) => {
      const value: number | null =
        featureWithColor.feature?.properties && layer.attributeKey
          ? featureWithColor.feature?.properties[layer.attributeKey]
          : null;

      return [
        ...results,
        {
          value,
          mappedValue: value !== null ? positionOnLegend(quantiles, value, 100) : null,
          color: featureWithColor.color,
          latLng: featureWithColor.latLng,
        },
      ];
    },
    []
  );
  return featureResults;
}
