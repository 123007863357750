import { useState } from "react";
import { MapControl } from "marvin-ui-kit";
import styled from "styled-components";
import { Fab, Grid, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon, List as ListIcon } from "@material-ui/icons";

import { MapWindowPaper } from "../surface/PaperBackground";
import { CustomMapControlBase } from "./CustomMapControlBase";
import { Spacer } from "../Spacer";
import { useAtom } from "jotai";
import { activeDemographicOverlayerAtom, activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { legendGraphicUrl } from "../../utils/ogc.utils";
import { BoxHeader } from "../BoxHeader";
import { activeFoodSwampOverlayerAtom } from "../../atoms/foodSwampOverlayers.atom";

export const DemographicOverlayerLegend = () => {
  const [activeDemographicOverlayer] = useAtom(activeDemographicOverlayerAtom);
  const [activeFoodSwampOverlayer] = useAtom(activeFoodSwampOverlayerAtom);
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [showLegend, setShowLegend] = useState<boolean>(true);

  function toggleLegend() {
    setShowLegend(!showLegend);
  }

  const activeLayer = activeDemographicOverlayer
    ? activeDemographicOverlayer
    : activeFoodSwampOverlayer
    ? activeFoodSwampOverlayer
    : activeSpatialOverlayer?.isShapeFile
    ? activeSpatialOverlayer
    : undefined;
  return render();

  function render() {
    if (!activeLayer) return null;

    return (
      <CustomMapControlBase position="bottomleft">
        <Styles>
          {showLegend && renderOpen()}
          {!showLegend && renderClosed()}
        </Styles>
      </CustomMapControlBase>
    );

    function renderOpen() {
      if (!activeLayer) return null;

      return (
        <MapControl>
          <BoxHeader>
            Legende
            <Spacer />
            <IconButton onClick={toggleLegend}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </BoxHeader>
          <Grid container component={MapWindowPaper} square direction="column">
            <div className="legend-content">
              <img src={legendGraphicUrl(activeLayer)} alt={`overlayer legend ${activeLayer.name}`} />
            </div>
            {activeLayer.extraInfo && <Typography>{activeLayer.extraInfo}</Typography>}
          </Grid>
        </MapControl>
      );
    }
    function renderClosed() {
      return (
        <Fab onClick={toggleLegend} size="medium" color="secondary">
          <ListIcon fontSize="small" />
        </Fab>
      );
    }
  }
};

const Styles = styled.div`
  width: fit-content;
  max-width: 200px;
`;
