import { SpatialOverlayer } from "../../types";

export const spatialOverlayers: SpatialOverlayer[] = [
  {
    key: "walkability_WLK-walkability_v1",
    name: "Walkabilityscore",
    includeInComparison: true,
    isActive: false,
    isShapeFile: false,
    isStatisticalSectorData: false,
    isViewable: true,
    extraDataLayers: [],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_WLK-walkability_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-walkability-woongebied_v1",
    name: "Walkabilityscore bebouwd gebied",
    includeInComparison: true,
    isActive: true,
    isViewable: true,
    extraDataLayers: [],
    isShapeFile: false,
    isStatisticalSectorData: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_WLK-walkability-woongebied_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-stratenconnectiviteit_v1",
    name: "Stratenconnectiviteit",
    isActive: false,
    isViewable: true,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: false,
    isStatisticalSectorData: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-stratenconnectiviteit-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-bevolking_v1",
    name: "Woondichtheid",
    isActive: false,
    isViewable: true,
    includeInComparison: true,
    isShapeFile: false,
    isStatisticalSectorData: false,
    extraDataLayers: [
      {
        layer: "walkability_inwoners-2019-100m-vl-br-anonymised_v1",
        label: "<%= value %> bewoners/ha",
      },
    ],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-woondichtheid-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-functiemix_v1",
    name: "Functiemix",
    isViewable: true,
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: false,
    isStatisticalSectorData: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-functiemix-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkscore",
    name: "Walkabilityscore per statische sector",
    isActive: false,
    isViewable: true,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: true,
    isStatisticalSectorData: true,
    attributeKey: "walkscore",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:20230712_statsec_walkabilityscore_voedselmoerasscore_kwetsbare_buurten",
      styles: "walkability-v2:walkscore",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-stratenconnectiviteit_statsec",
    name: "Stratenconnectiviteit per statische sector",
    isViewable: false,
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: true,
    isStatisticalSectorData: true,
    attributeKey: "STRTCONN_m",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:20231023_deelindicatoren_walkbility_bebouwdgebied",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-bevolking_statsec",
    name: "Woondichtheid per statische sector",
    isActive: false,
    isViewable: false,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: true,
    isStatisticalSectorData: true,
    attributeKey: "WOONDICHT_",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:20231023_deelindicatoren_walkbility_bebouwdgebied",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-functiemix_statsec",
    name: "Functiemix per statische sector",
    isViewable: false,
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [],
    isShapeFile: true,
    isStatisticalSectorData: true,
    attributeKey: "FMIX_mean",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:20231023_deelindicatoren_walkbility_bebouwdgebied",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
];
